import { Card } from '@involve-software/uikit';

import { ScButton } from 'components/button/styled';
import { device } from 'components/global/MediaQuery';
import { ScCommentBtn, ScInputBlock } from 'components/input/styled';

import сloseIcon from 'imgs/close-gray-icon.svg';
import lockIcon from 'imgs/lock-icon.svg';
import styled, { css } from 'styled-components/macro';

interface ScBlockProps {
  marginTop?: boolean;
  small?: boolean;
  center?: boolean;
  locked?: boolean;
  verticalAlign?: boolean;
}

export const ScBlock = styled.div<ScBlockProps>`
  position: relative;
  ${({ verticalAlign }) =>
    verticalAlign ? `top: 50%; transform: translateY(-50%);` : null};
  ${({ small }) => (small ? `width: 460px;` : null)};
  ${({ center }) => (center ? `margin: 0 auto;` : null)};
  background: ${({ theme }) => theme.colors['layer-01']};
  border-radius: ${({ theme }) => theme.radius['radius-modal']};
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  box-sizing: border-box;
  ${({ marginTop, theme }) =>
    marginTop ? `margin-top:${theme.spacing.primitives['s-24']}` : null};
  @media (max-width: 768px) {
    width: 100%;
  }
  &:nth-child(1n + 2) {
    margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};
    @media (${device.tablet}) {
      margin-top: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
  }
  &:before {
    content: ${({ locked }) => (locked ? "''" : 'none')};
    background: rgba(251, 252, 253, 0.8) url(${lockIcon}) no-repeat 50% 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    border-radius: ${({ theme }) => theme.radius['radius-cards-strong']};
  }
  h6 {
    font: ${({ theme }) => theme.typography['label-large']};
    color: ${({ theme }) => theme.colors['text-primary']};
    margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-16']} 0;
    @media (${device.tablet}) {
      font: ${({ theme }) => theme.typography['label-medium']};
    }
  }
`;

export const ScBlockWithPaddings = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-28']};
`;

export const ScBlockHeader = styled.div<{
  withButtonClose?: boolean;
  $noBorder?: boolean;
}>`
  border-radius: ${({ theme }) => theme.radius['radius-cards-strong']}
    ${({ theme }) => theme.radius['radius-cards-strong']} 0 0;
  color: ${({ theme }) => theme.colors['text-primary']};
  margin: ${({ theme }) =>
    `0 ${theme.spacing.primitives['s-28']} ${theme.spacing.primitives['s-24']}`};
  padding: ${({ theme }) =>
    `${theme.spacing.primitives['s-36']} 0 ${theme.spacing.primitives['s-12']}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  position: relative;

  ${({ $noBorder }) => ($noBorder ? `border: 0;` : null)};

  @media (${device.mobileL}) {
    margin: ${({ theme }) =>
      `0 ${theme.spacing.primitives['s-28']} ${theme.spacing.primitives['s-12']}`};
  }

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['heading-small']};
    padding-right: ${({ withButtonClose, theme }) =>
      withButtonClose ? theme.spacing.primitives['s-24'] : '0'};
    & > div {
      display: inline-block;
    }
    span {
      color: ${({ theme }) => theme.colors['text-primary']};
      font: ${({ theme }) => theme.typography['paragraph-x-small']};
      cursor: pointer;
    }
    b {
      font-weight: bolder;
      i {
        font-style: normal;
        @media (${device.mobileL}) {
          display: none;
        }
      }
    }
  }
`;

export const ScBlockWithCaptcha = styled(Card)`
  margin: 0 auto;
  width: 100%;
  max-width: 455px;
`;

export const ScButtonClose = styled.button`
  display: block;
  background-color: transparent;
  background-image: url(${сloseIcon});
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.spacing.primitives['s-24']};
  cursor: pointer;
`;

export const ScBlockBody = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-28']}
    ${({ theme }) => theme.spacing.primitives['s-28']};
  position: relative;
  .icon {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
  }
`;
export const ScBlockBodyNoHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.primitives['s-20']}
    ${({ theme }) => theme.spacing.primitives['s-28']};
  h2 {
    font-style: normal;
    font: ${({ theme }) => theme.typography['heading-small']};
  }
  p {
    font: ${({ theme }) => theme.typography['paragraph-small']};
  }

  .code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .important-info {
    font-weight: bold;
  }
`;
export const ScBlockBodyTitle = styled.h2`
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  padding: ${({ theme }) => theme.spacing.primitives['s-20']} 0;
  margin: 0;
  text-align: center;
`;
export const ScBlockBodyHeader = styled.div<{ noPaddingTop?: boolean }>`
  padding: ${({ noPaddingTop, theme }) =>
      noPaddingTop ? '0' : theme.spacing.primitives['s-20']}
    ${({ theme }) => theme.spacing.primitives['s-28']}
    ${({ theme }) => theme.spacing.primitives['s-20']};
  h2 {
    font: ${({ theme }) => theme.typography['paragraph-medium']};
    margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-20']} 0;
    color: ${({ theme }) => theme.colors['text-secondary']};
  }
  p {
    color: ${({ theme }) => theme.colors['text-subtle']};
    font: ${({ theme }) => theme.typography['paragraph-small']};
  }
`;

export const ScBlockBodyTitleExpanded = styled(ScBlockBodyTitle)`
  padding: ${({ theme }) => theme.spacing.primitives['s-10']}
    ${({ theme }) => theme.spacing.primitives['s-20']}
    ${({ theme }) => theme.spacing.primitives['s-20']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};

  @media (${device.mobileL}) {
    font: ${({ theme }) => theme.typography['paragraph-small']};
  }
`;

export const ScBlockBodyExpanded = styled.div`
  padding: ${({ theme }) => theme.spacing.primitives['s-20']} 0;
`;
export const ScBlockSection = styled.div`
  padding: 0;
  background: ${({ theme }) => theme.colors['layer-01']};
`;
export const ScBlockSectionHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  padding: ${({ theme }) => theme.spacing.primitives['s-12']}
    ${({ theme }) => theme.spacing.primitives['s-28']};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['heading-medium']};
    img {
      width: 24px;
      height: 24px;
      margin-right: ${({ theme }) => theme.spacing.primitives['s-08']};
    }
  }
`;
export const ScBlockSectionBody = styled.div`
  padding: 0;
`;
interface ScWrapProps {
  noPadding?: boolean;
  bordered?: boolean;
  spaceBetween?: boolean;
  topPadding?: string;
  bottomPadding?: string;
  flying?: boolean;
  vertical?: boolean;
  customVersion?: boolean;
  customButton?: boolean;
  fullWidthButton?: boolean;
}
export const ScWrap = styled.div<ScWrapProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives['s-04']};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'center'};
  ${({ bordered, theme }) =>
    bordered && `border-top: 1px solid ${theme.colors['border-subtle-01']};`};
  position: relative;
  ${({ noPadding, theme }) =>
    noPadding
      ? null
      : `padding: ${theme.spacing.primitives['s-28']} 0; margin: 0 ${theme.spacing.primitives['s-28']};`};
  ${({ bottomPadding }) =>
    bottomPadding && `padding-bottom: ${bottomPadding}px;`};
  ${({ topPadding }) => topPadding && `padding-top: ${topPadding}px;`};
  button,
  a {
    ${({ customButton }) => (customButton ? 'width: 184px' : null)};
    flex: ${({ fullWidthButton }) => (fullWidthButton ? '1 1 50%' : 'initial')};
  }
  .button-link {
    ${ScButton} {
      width: 100%;
    }
  }
  @media (${device.tablet}) {
    flex-direction: column;
    ${({ customVersion, theme }) =>
      customVersion
        ? `padding: ${theme.spacing.primitives['s-24']} ${theme.spacing.primitives['s-28']}; margin: 0; flex-direction: column-reverse;`
        : null};
  }
  button,
  a {
    margin-right: ${({ theme }) => theme.spacing.primitives['s-20']};
    @media (${device.tablet}) {
      margin: ${({ customVersion, theme }) =>
        customVersion
          ? `${theme.spacing.primitives['s-16']} 0 0`
          : `${theme.spacing.primitives['s-12']} 0`};
      width: 100%;
    }
    button {
      @media (${device.tablet}) {
        width: 100%;
      }
    }
    &:last-child {
      margin: 0;
    }
  }

  ${({ flying, theme }) =>
    flying
      ? 'position: fixed;' +
        'z-index: 10; ' +
        'bottom: 0;' +
        'margin: 0;' +
        `background:${theme.colors['layer-01']}E6;` +
        'width: 755px;' +
        `padding-bottom: ${theme.spacing.primitives['s-28']};` +
        'border: 0;' +
        `@media (${device.desktop}) {padding-bottom: 70px; width: calc(100% - 35px);};` +
        `@media (${device.tablet}) {width: 100%;};` +
        '& > div{position: absolute; background: rgba(255, 255, 255, .9); top: -60px; border-top: 1px solid rgba(166, 171, 178, .3)}'
      : null};
  ${({ vertical, theme }) =>
    vertical
      ? `@media (${device.mobileL}) {button{width: 100%; margin: 0 0 ${theme.spacing.primitives['s-04']} 0;}}`
      : null};
  box-sizing: border-box;

  form {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
`;

interface ScLabeledBlockProps {
  noBorder?: boolean;
  small?: boolean;
  paddingTop?: string;
  paddingBottom?: string;
  borderTop?: true;
  mobMarginTop?: boolean;
  baseLine?: boolean;
  alignCenter?: boolean;
}

export const ScLabeledBlock = styled.div<ScLabeledBlockProps>`
  ${({ small, theme }) =>
    small ? null : `padding: ${theme.spacing.primitives['s-28']} 0;`}
  ${({ noBorder, theme }) =>
    noBorder
      ? null
      : `border-bottom: 1px solid ${theme.colors['border-subtle-01']};`}
  display: flex;
  ${({ baseLine, alignCenter }) =>
    alignCenter
      ? 'align-items: center;'
      : baseLine
        ? 'align-items: baseline;'
        : 'align-items: flex-start;'}
  margin: 0 ${({ theme }) => theme.spacing.primitives['s-28']};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}` : null)};
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}` : null}
  ${({ borderTop, theme }) =>
    borderTop
      ? `border-top: 1px solid ${theme.colors['border-subtle-01']};`
      : null};

  @media (${device.tablet}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.primitives['s-16']} 0;
    border: 0;
    ${({ mobMarginTop, theme }) =>
      mobMarginTop && `margin-top: ${theme.spacing.primitives['s-24']};`}
  }
`;

interface ScLabeledBlockTitleProps {
  // TODO: Remove legacy "center" property
  center?: boolean;
  alignCenter?: boolean;
  marginTop?: string;
}

export const ScLabeledBlockTitle = styled.div<ScLabeledBlockTitleProps>`
  width: 165px;
  margin-right: ${({ theme }) => theme.spacing.primitives['s-16']};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : null)}
  ${({ alignCenter }) => (alignCenter ? `align-self: center;` : null)}
    ${({ center }) =>
    center
      ? `
  display: flex;
  flex-direction: column;
  justify-content: center;`
      : null};

  @media (${device.tablet}) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
    &:empty {
      margin: 0;
    }
  }
  h2 {
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['label-medium']};
    margin: 0;
    &.caption {
      color: ${({ theme }) => theme.colors['text-subtle']};
    }
  }
  p {
    color: ${({ theme }) => theme.colors['text-subtle']};
    font: ${({ theme }) => theme.typography['paragraph-x-small']};
    margin: 0;
    &.mobMarginTop {
      margin-top: ${({ theme }) => theme.spacing.primitives['s-04']};
    }
  }
`;
export const ScLabeledBlockBody = styled.div`
  position: relative;
  width: 60%;
  & > div {
    width: 425px;
  }
  @media (${device.tablet}) {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;

export const ScCommentBlock = styled.div`
  & > div {
    margin: 0;
  }
  @media (${device.tablet}) {
    max-width: 100%;
  }
  .input__label {
    color: ${({ theme }) => theme.colors['text-brand']};
  }
  ${ScInputBlock} + ${ScCommentBtn} {
    background-color: ${({ theme }) => theme.colors['layer-02']};
    margin-top: ${({ theme }) => theme.spacing.primitives['s-24']};
  }
`;

export const ScEndingPageBody = styled.div`
  text-align: center;
  width: 400px;
  margin: ${({ theme }) => theme.spacing.primitives['s-28']} auto 0 auto;
  @media (${device.mobileXL}) {
    width: 100%;
  }
  h3 {
    color: ${({ theme }) => theme.colors['text-primary']};
    margin: ${({ theme }) => theme.spacing.primitives['s-12']} 0
      ${({ theme }) => theme.spacing.primitives['s-08']} 0;
    font: ${({ theme }) => theme.typography['heading-medium']};

    @media (${device.mobileXL}) {
      margin: ${({ theme }) => theme.spacing.primitives['s-24']} 0
        ${({ theme }) => theme.spacing.primitives['s-08']} 0;
    }
  }
  p {
    color: ${({ theme }) => theme.colors['text-secondary']};
    font: ${({ theme }) => theme.typography['paragraph-small']};
    margin-bottom: 0;
  }
`;

export const ScTabMenu = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.primitives['s-28']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  a,
  button {
    position: relative;
    padding: ${({ theme }) => theme.spacing.primitives['s-08']}
      ${({ theme }) => theme.spacing.primitives['s-12']};
    margin: 0 ${({ theme }) => theme.spacing.primitives['s-24']} 0 0;
    display: inline-block;
    border: none;
    color: ${({ theme }) => theme.colors['text-subtle']};
    transition: color 0.3s ease;
    font: ${({ theme }) => theme.typography['label-small']};
    background: transparent;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1.5px;
      height: 3px;
      background-color: transparent;
    }
    &:last-child {
      margin: 0;
    }
    &.active {
      color: ${({ theme }) => theme.colors['text-primary']};
      &:after {
        background-color: ${({ theme }) => theme.colors['border-button']};
      }
    }
    &:hover {
      color: ${({ theme }) => theme.colors['text-primary']};
      text-decoration: none;
    }
  }
  @media (${device.tablet}) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-right: 0;
  }
  @media (${device.tablet}) {
    margin-left: ${({ theme }) => theme.spacing.primitives['s-16']};
  }
`;

export const ScTabMenuPersonal = styled(ScTabMenu)`
  display: flex;
  justify-content: space-between;
  a,
  button {
    margin: 0;
    @media (${device.mobileXXL}) {
      margin-right: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
    &:last-child {
      margin: 0;
    }
  }
`;
export const ScGaBlock = styled.div`
  width: 100%;
  flex: 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 290px;
    margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-20']} 0;
    box-sizing: border-box;
    @media (${device.mobileL}) {
      padding-left: ${({ theme }) => theme.spacing.primitives['s-28']};
      padding-right: ${({ theme }) => theme.spacing.primitives['s-28']};
      width: 100%;
    }
  }
`;
interface ScListBlockProps {
  marginTop?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  small?: boolean;
  summary?: boolean;
}
export const ScListBlock = styled.ul<ScListBlockProps>`
  display: flex;
  align-self: flex-end;
  flex: 1 100%;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 ${({ theme }) => theme.spacing.primitives['s-28']};
  ${({ marginTop, theme }) =>
    marginTop ? `margin-top:${theme.spacing.primitives['s-28']}` : null};
  padding: ${({ small, theme }) =>
    small ? '0' : `${theme.spacing.primitives['s-28']} 0`};
  border: 0;
  transition: 0.3s ease;
  ${({ borderBottom, theme }) =>
    borderBottom
      ? `border-bottom:1px solid ${theme.colors['border-subtle-01']};`
      : null};
  ${({ borderTop, theme }) =>
    borderTop
      ? `border-top:1px solid ${theme.colors['border-subtle-01']};`
      : null};
  @media (${device.tablet}) {
    padding: ${({ small, theme }) =>
      small ? '0' : `${theme.spacing.primitives['s-16']} 0`};
    ${({ marginTop, theme }) =>
      marginTop ? `margin-top:${theme.spacing.primitives['s-16']}` : null};
  }
  li {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-12']};
    font: ${({ theme }) => theme.typography['label-medium']};
    color: ${({ theme }) => theme.colors['text-subtle']};
    text-align: left;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    &:last-child {
      margin: 0;
      border: 0;
    }
    &.total {
      color: ${({ theme }) => theme.colors['text-primary']};
      border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
      padding-top: ${({ theme }) => theme.spacing.primitives['s-12']};
      margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};
    }
    .word-break {
      word-break: break-all;
    }
    span {
      display: inline-block;
      ${({ summary, theme }) =>
        summary
          ? `color: ${theme.colors['text-primary']}; font: ${theme.typography['label-medium']}`
          : null};
      &:first-child {
        min-width: 176px;
        width: 176px;
        margin-right: ${({ theme }) => theme.spacing.primitives['s-24']};
        @media (${device.tablet}) {
          margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
        }
      }
      &:last-child {
        color: ${({ theme }) => theme.colors['text-primary']};
        display: flex;
        align-items: center;
        font-weight: ${({ summary }) => (summary ? '700' : '400')};
        @media (${device.mobileXXL}) {
          margin-top: ${({ theme }) => theme.spacing.primitives['s-04']};
        }
        img {
          width: 25px;
          margin-right: ${({ theme }) => theme.spacing.primitives['s-04']};
        }
      }
    }

    @media (${device.mobileXXL}) {
      flex-direction: column;
    }
  }
`;

export const ScErrorBlock = styled.div<{ marginTop?: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors['layer-01']};
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['label-medium']};
  /*TODO add export radius primitives from uikit*/
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  box-sizing: border-box;
  -webkit-animation: slowShow 1s;
  animation: slowShow 1s;
  padding: ${({ theme }) => theme.spacing.primitives['s-24']}
    ${({ theme }) => theme.spacing.primitives['s-16']};
  display: flex;
  align-items: center;
  overflow: hidden;
  ${({ marginTop, theme }) =>
    marginTop ? `margin-top: ${theme.spacing.primitives['s-28']}` : null};
  img {
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.spacing.primitives['s-16']};
  }
  &:before {
    content: '';
    position: absolute;
    /*TODO add export radius primitives from uikit*/
    border-radius: 12px 0 0 12px;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: ${({ theme }) => theme.colors['border-danger']};
  }
  button {
    font: ${({ theme }) => theme.typography['paragraph-small']};
  }
  .error-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-06']};
    }
  }
`;

export const ScDelShopBlock = styled(ScLabeledBlock)`
  justify-content: space-between;

  @media (${device.tablet}) {
    border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
    padding-top: ${({ theme }) => theme.spacing.primitives['s-24']};
  }

  ${ScLabeledBlockTitle} {
    width: 496px;
    margin-right: ${({ theme }) => theme.spacing.primitives['s-36']};

    @media (${device.tablet}) {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-12']};
    }
  }

  ${ScLabeledBlockBody} {
    width: initial;
  }

  button {
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
  }
`;

const DEFAULT_DIRECTION = 'column';

export const ScInputGroup = styled.div<{ $direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ $direction = DEFAULT_DIRECTION }) => $direction};
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};

    ${({ $direction = DEFAULT_DIRECTION }) =>
      $direction === 'row' &&
      css`
        margin-bottom: 0;
      `}
  }
  & .addPhone-btn {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
    margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};
  }

  ${({ $direction = DEFAULT_DIRECTION }) =>
    $direction === 'row' &&
    css`
      gap: ${({ theme }) => theme.spacing.primitives['s-08']};
    `}
`;

export const ScFormMessage = styled.div`
  color: ${({ theme }) => theme.colors['text-secondary']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
`;

export const ScSendEmailGroup = styled.div`
  text-align: center;
  font: ${({ theme }) => theme.typography['underlined-medium']};
  color: ${({ theme }) => theme.colors['text-disabled-subtle']};
  flex: 1 1 50%;
`;

export const ScTextAlignRight = styled.span`
  width: 100%;

  a {
    float: right;

    @media (hover: none) {
      &:hover {
        border-color: transparent;
      }
    }
  }
`;

export const ScErrorText = styled.p`
  color: ${({ theme }) => theme.colors['text-danger']};
  text-align: center;
  padding: 0;
  margin: ${({ theme }) => theme.spacing.primitives['s-28']} 0
    ${({ theme }) => theme.spacing.primitives['s-08']} 0;
  height: 13px;
  font: ${({ theme }) => theme.typography['paragraph-small']};
`;

export const ScBlockResult = styled.div`
  padding: ${({ theme }) => theme.spacing.primitives['s-24']} 0;
  margin: 0 ${({ theme }) => theme.spacing.primitives['s-28']};
  border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  @media (${device.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.primitives['s-12']};
  }
`;

export const ScBlockResultItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
  @media (${device.tablet}) {
    justify-content: space-between;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ScBlockResultItemTitle = styled.div`
  width: 180px;
  margin-right: ${({ theme }) => theme.spacing.primitives['s-24']};
  @media (${device.tablet}) {
    width: auto;
  }
  h2 {
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['label-medium']};
    margin: 0;
    &.small {
      color: ${({ theme }) => theme.colors['text-subtle']};
      font: ${({ theme }) => theme.typography['paragraph-x-small']};
    }
  }
`;

export const ScBlockResultItemBody = styled.div`
  position: relative;
  width: 60%;
  @media (${device.tablet}) {
    width: auto;
  }
  .text-block {
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['heading-large']};
    margin: 0;
  }
`;

export const ScInputAddon = styled.div`
  font: ${({ theme }) => theme.typography['label-small']};
`;

export const ScFormVerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 -${({ theme }) => theme.spacing.primitives['s-12']};
  @media (${device.tablet}) {
    flex-wrap: wrap;
  }
`;

export const ScFormVerCol1 = styled.div`
  flex: 0 0 322px;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-12']};
  @media (${device.tablet}) {
    flex: auto;
  }
`;

export const ScFormVerCol2 = styled.div`
  flex: 1 1 270px;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-12']};
  @media (${device.tablet}) {
    flex: auto;
    margin-top: ${({ theme }) => theme.spacing.primitives['s-28']};
  }
`;

export const ScRules = styled.div`
  h3 {
    font: ${({ theme }) => theme.typography['heading-x-small']};
    margin-top: 0;
  }

  .image {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
    img {
      width: 100%;
      vertical-align: top;
    }
  }

  .selfie-warning {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    &__img {
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
      img {
        width: 100%;
        vertical-align: top;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      font: ${({ theme }) => theme.typography['paragraph-small']};
      color: ${({ theme }) => theme.colors['text-primary']};
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
      &:last-child {
        margin-bottom: 0;
      }
      .ico {
        padding-right: ${({ theme }) => theme.spacing.primitives['s-08']};
      }
      .text {
        flex: auto;
        &__item {
          margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
          & > span {
            color: ${({ theme }) => theme.colors['text-subtle']};
            font: ${({ theme }) => theme.typography['label-small']};
            margin-right: ${({ theme }) => theme.spacing.primitives['s-04']};
          }
        }
        &__row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          margin: 0 -${({ theme }) => theme.spacing.primitives['s-04']};
          box-sizing: border-box;
        }
        &__col {
          width: 50%;
          padding: 0 ${({ theme }) => theme.spacing.primitives['s-04']};
          box-sizing: border-box;
        }
        &__t-min {
          color: ${({ theme }) => theme.colors['text-primary']};
          margin-bottom: ${({ theme }) => theme.spacing.primitives['s-08']};
        }
      }
    }
  }
`;

export const ScOnReviewBlock = styled.div`
  width: 100%;
  text-align: center;
  padding: 0;
  @media (${device.tablet}) {
    padding: ${({ theme }) => theme.spacing.primitives['s-28']} 0;
  }
  .ico {
    width: 100px;
    height: 100px;
    margin: 0 auto ${({ theme }) => theme.spacing.primitives['s-16']};
    border-radius: 50%;
    line-height: 100px;
    img {
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }
  .message {
    max-width: 360px;
    margin: 0 auto;
    h3 {
      font: ${({ theme }) => theme.typography['heading-medium']};
      margin: 0;
      color: ${({ theme }) => theme.colors['text-primary']};
    }
    p {
      font: ${({ theme }) => theme.typography['paragraph-small']};
      color: ${({ theme }) => theme.colors['text-secondary']};
      margin: ${({ theme }) => theme.spacing.primitives['s-08']} 0 0;
    }
  }
`;

export const ScRelativeBlock = styled.div`
  position: relative;
`;

export const ScBreakWord = styled.div`
  word-break: break-word;
`;

export const ScFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (${device.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
